import * as React from "react";
import { Show, SimpleShowLayout, TextField, FunctionField } from "react-admin";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const styles = {
  fontSize: "1.2rem",
};

const VideoField = ({ label = "Видео", record }) => {
  if (!record?.youtubeCode) return null;
  return (
    <iframe
      title={label}
      src={`https://www.youtube.com/embed/${record.youtubeCode}`}
      style={{ border: "none" }}
      allowFullScreen
      controls
      width="100%"
      height="500"
    ></iframe>
  );
};

VideoField.defaultProps = { label: "Видео" };

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const VideoLink = (props) => {
  const { youtubeCode } = props;
  if (!youtubeCode) return null;
  return (
    <a href={`https://www.youtube.com/embed/${youtubeCode}`} target="_blank" rel="noopener noreferrer">
      Видео
    </a>
  );
};

const UserShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Item>
                <FunctionField
                  label="Видео"
                  render={(record) => <VideoField record={record} />}
                />
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <FunctionField
                  label="Видео"
                  render={(record) => <VideoLink record={record} youtubeCode={record.youtubeCode} />}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography>Име</Typography>
                <TextField source="firstName" sx={styles} />
                <TextField
                  source="lastName"
                  sx={{ fontSize: "1.2rem", marginLeft: 1 }}
                />
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <Typography>Дата на раждане</Typography>
                <TextField source="dateOfBirth" sx={styles} />
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <Typography>От къде сте</Typography>
                <TextField source="whereFrom" sx={styles} />
              </Item>
            </Grid>
            <Grid item xs={2}>
              <Item>
                <Typography>Държавен код</Typography>
                <TextField source="countryCode" sx={styles} />
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <Typography>Телефонен номер</Typography>
                <TextField source="phoneNumber" sx={styles} />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography>Имейл</Typography>
                <TextField source="email" sx={styles} />
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <Typography>Как сте ни открили</Typography>
                <TextField source="howYouFindIt" sx={styles} />
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <Typography>Причина за участие</Typography>
                <TextField source="reason" sx={styles} />
              </Item>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: 1 }}>
            <Grid item xs={3}>
              <Item>
                <Typography>Безспечно ли е за забавление</Typography>
                <TextField source="bedForFun" sx={styles} />
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <Typography>Ниво на английски</Typography>
                <TextField source="englishLevel" sx={styles} />
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <Typography>Технически умения</Typography>
                <TextField source="technicalSkills" sx={styles} />
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <Typography>Приемате ли критика</Typography>
                <TextField source="acceptsCriticism" sx={styles} />
              </Item>
            </Grid>

            <Grid item xs={12}>
              <Item>
                <Typography>Предимства</Typography>
                <TextField source="advantages" sx={styles} />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography>Най-голяма загуба</Typography>
                <TextField source="biggestLose" sx={styles} />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography>Най-голям успех</Typography>
                <TextField source="biggestWin" sx={styles} />
              </Item>
            </Grid>

            <Grid item xs={6}>
              <Item>
                <Typography>Линк към социална мрежа</Typography>
                <TextField source="socialMediaLink" sx={styles} />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography>Три думи за вас</Typography>
                <TextField source="treeWords" sx={styles} />
              </Item>
            </Grid>
          </Grid>
        </Box>
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
