import * as React from 'react';
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Controller } from "react-hook-form";
import { bg } from "date-fns/locale";

export default function CompDateTimePicker({ label, control, name }) {
  if (!control) {
    console.error("Control is not provided for the DateTimePicker component.");
    return null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={bg}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <DateTimePicker
            label={label}
            value={value || null}  // Ensure value is not undefined
            onChange={onChange}    // Pass onChange directly from Controller
            slots={{
              textField: (params) => (
                <TextField
                  {...params}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )
            }}
            // Ensure DateTimePicker is properly rendered
            PopperProps={{
              style: { zIndex: 1300 } // Ensure Popper is not obscured
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
