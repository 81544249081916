import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import DomainIcon from '@mui/icons-material/Domain';
import { useController } from "react-hook-form";

const dropdownBrandMenu = {
  display: "flex",
  alignItems: "center",
};
const DropdownBrandMenu = React.memo(({ name, control, onChange }) => {
  const { field } = useController({
    name,
    control,
    defaultValue: "bg",
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    setAnchorEl(null);
    if (value !== undefined) {
      field.onChange(value);
      if (onChange) onChange(value);
    }
  };

  return (
    <div>
      <Typography component="span" style={{ marginLeft: 8 }}>
        {"brand".toUpperCase()}
      </Typography>
      <div style={dropdownBrandMenu}>
      <IconButton onClick={handleClick}>
        <DomainIcon />
      </IconButton>
      <Typography component="span" style={{ marginLeft: 8 }}>
        {field.value.toUpperCase()}
      </Typography>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
      >
        <MenuItem onClick={() => handleClose("bg")}>BG</MenuItem>
        <MenuItem onClick={() => handleClose("fm")}>FM</MenuItem>
      </Menu>
    </div>
    </div>
   
  );
});

export default DropdownBrandMenu;
