import React from 'react'
import DynamicFormInput from './DynamicFormInput'


function WomenGame() {
  return (
    <div>
      <h1>WOMEN GAME WINNERS</h1>
      <DynamicFormInput/>
    </div>
  )
}

export default WomenGame