import React from 'react'
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';

const UserList = (props) => {
    return (
        <List {...props } >
            <Datagrid>
            <TextField source='id' />
            <TextField source='email' />
            <TextField source='roles' />
            <EditButton source='id' />
            <DeleteButton source='id' />
            </Datagrid>
        </List>
    )
}

export default UserList