// client/src/components/UserCreate.js
import * as React from 'react';
import { Create, SimpleForm, TextInput, PasswordInput } from 'react-admin';

const UserCreate = (props) => {
  console.log('UserCreate Props:', props);

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="email" label="Email" />
        <PasswordInput source="password" label="Password" />
        <TextInput source="roles" label="Roles" />
      </SimpleForm>
    </Create>
  );
}

export default UserCreate;
