import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Button, TextField, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DropdownBrandMenu from "./DropdownBrandMenu";
import CompDateTimePicker from "./CompDateTimePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { format } from 'date-fns';

const formStyle = {
  display: "grid",
  justifyContent: "start",
  rowGap: "30px",
  padding: "0 0 100px 0",
};
const inputRowStyle = {
  display: "grid",
  gridTemplateColumns: "50px minmax(auto, 250px) minmax(auto, 250px) max-content max-content",
  gap: "10px",
  alignItems: "start",
  margin: "0",
};
const submitButton = {
  marginLeft: "auto",
};
const dateWrapper = {
  display: "flex",
  justifyContent: "space-between",
};

const schema = yup.object().shape({
  winners: yup.array().of(
    yup.object().shape({
      username: yup.string().required("Потребителското име е задължително").min(3, "Минимум 3 символа"),
      reward: yup.string().required("Наградата е задължителна").min(3, "Минимум 3 символа"),
    })
  ),
  startDate: yup.date().required("Стартовата дата е задължителна"),

  endDate: yup.date().required("Крайната дата е задължителна"),

  videoUrl: yup.string().required("Невалиден URL адрес"),
});

const DynamicFormInput = () => {
  const [loading, setLoading] = useState(false);
  const [brand, setBrand] = useState("bg");


  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      winners: [{ id: "1", username: "", reward: "" }],
      fileName: "women-winners.json",
      brand: brand,
      startDate: null,
      endDate: null,
      videoUrl: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "winners",
  });

  const onSubmit = async (data) => {
    setLoading(true);

    
    try {
      const response = await fetch(`https://casting-api.palms.plus/api/sftp/upload`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          winners: data.winners,
          fileName: data.fileName,
          brand,
          startDate: data.startDate ? format(data.startDate, "yyyy-MM-dd'T'HH:mm:ss") : null,
          endDate: data.endDate ? format(data.endDate, "yyyy-MM-dd'T'HH:mm:ss") : null,
          videoUrl: data.videoUrl,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to upload data");
      }

      const result = await response.json();
      toast.success(result.message,{
        position: "bottom-right"
     });
    } catch (error) {
      toast.error("Upload failed. Please try again.",{
        position: "bottom-right"
     });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fields.forEach((field, index) => {
      setValue(`winners[${index}].id`, (index + 1).toString());
    });
  }, [fields, setValue]);

  return (
    // <LocalizationProvider dateAdapter={AdapterDateFns} locale={bg}>
    <div>
      <DropdownBrandMenu name="brand" control={control} onChange={setBrand} />
      <form onSubmit={handleSubmit(onSubmit)} style={formStyle}>
        <Button style={submitButton} type="submit" variant="contained" color="primary" endIcon={loading ? <CircularProgress size={24} /> : <SaveAsOutlinedIcon />} disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </Button>

        <div style={dateWrapper}>
          <CompDateTimePicker name="startDate" control={control} label="Start date" />

          <CompDateTimePicker label="End date" name="endDate" control={control} />
        </div>

        <Controller name="videoUrl" control={control} render={({ field }) => <TextField style={{ margin: "0" }} label="Youtube" variant="outlined" {...field} placeholder="Youtube embed code" error={!!errors.videoUrl} helperText={errors.videoUrl ? errors.videoUrl.message : ""} />} />

        {fields.map((field, index) => (
          <div key={field.id} style={inputRowStyle} className="input-row">
            <Controller name={`winners[${index}].id`} control={control} render={({ field }) => <TextField style={{ margin: "0" }} disabled label="#" variant="outlined" {...field} />} />
            <Controller
              name={`winners[${index}].username`}
              control={control}
              render={({ field }) => <TextField style={{ margin: "0" }} label="ID" variant="outlined" {...field} placeholder="ID на потребител" error={!!errors.winners?.[index]?.username} helperText={errors.winners?.[index]?.username ? errors.winners[index].username.message : ""} />}
            />
            <Controller
              name={`winners[${index}].reward`}
              control={control}
              render={({ field }) => <TextField style={{ margin: "0" }} label="Награда" variant="outlined" {...field} placeholder="Име на награда" error={!!errors.winners?.[index]?.reward} helperText={errors.winners?.[index]?.reward ? errors.winners[index].reward.message : ""} />}
            />
            {index === fields.length - 1 && (
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() =>
                  append({
                    id: (fields.length + 1).toString(),
                    username: "",
                    reward: "",
                  })
                }
              >
                Добави
              </Button>
            )}
            {fields.length > 1 && (
              <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => remove(index)}>
                Изтрий
              </Button>
            )}
          </div>
        ))}
      </form>
      <ToastContainer />
    </div>
    // </LocalizationProvider>
  );
};

export default DynamicFormInput;
