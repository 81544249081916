import React from 'react'
import { List, Datagrid, TextField, } from 'react-admin';

const UserList = (props) => {
    return (
        <List {...props } >
            <Datagrid rowClick="show">
            <TextField source='firstName' label='Име'/>
            <TextField source='lastName' label='Фамилия'/>
            <TextField source='phoneNumber' label='Тел.'/>
            <TextField source='whereFrom' label='Град'/>
            <TextField source='id' label='id'/>
            <TextField source='createdAt' label='Created At'/>
            </Datagrid>
        </List>
    )
}

export default UserList