import * as React from "react";
import { forwardRef } from 'react';
import { useLogout } from 'react-admin';
import { MenuItem } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';



const LogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const handleClick = () => logout();
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
            {...props}
        >
            <PowerSettingsNewIcon /> Logout
        </MenuItem>
    );
  });

  export default LogoutButton