// client/src/App.js
import * as React from "react";
import { Admin, Resource, AppBar, Layout, UserMenu } from "react-admin";
import LoginPage from "./components/user/LoginPage";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import LogoutButton from "./components/LogoutButton";
import roleBasedResources from "./permissions"; // Импортиране на ролевите базирани ресурси

const MyUserMenu = () => <UserMenu><LogoutButton /></UserMenu>;

const MyAppBar = () => <AppBar userMenu={<MyUserMenu />} />;

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} />;

const App = () => (
  <Admin layout={MyLayout} loginPage={LoginPage} dataProvider={dataProvider} authProvider={authProvider}>
    {permissions => {
      const roles = permissions?.roles;
      if (!roles) return null;

      // Вземане на ресурсите въз основа на ролите на потребителя
      const resources = roleBasedResources[roles] || [];

      return (
        <>
          {resources.map(({ name, component: List, create, edit, show }) => (
            <Resource
              key={name}
              name={name}
              options={{ label: name.replace('-', ' ').toUpperCase() }}
              list={List}
              create={create}
              edit={edit} // Добавете компонент за редактиране
              show={show}
            />
            
          ))}
        </>
      );
    }}
  </Admin>
);

export default App;
