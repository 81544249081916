// client/src/components/AdminUserUpdate.js
import * as React from 'react';
import { Edit, SimpleForm, TextInput, PasswordInput, required } from 'react-admin';

const AdminUserEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="email" label="Email" validate={required()} />
        <PasswordInput source="password" label="Password" />
        <TextInput source="roles" label="Roles" />
      </SimpleForm>
    </Edit>
  );
}

export default AdminUserEdit;
